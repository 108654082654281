import { gql, useQuery } from "@apollo/client";
import React from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";

const REFUND_POLICY = gql`
  query getRefundPolicy {
    shop {
      refundPolicy {
        body
        title
      }
    }
  }
`;

const RefundPolicy = () => {
  const { loading, data } = useQuery(REFUND_POLICY);

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container px-4 mx-auto my-8">
          <h1 className="font-bold text-3xl mb-4">{data.shop.refundPolicy.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.shop.refundPolicy.body }}
          ></div>
        </div>
      )}
    </Layout>
  );
};

export default RefundPolicy;
